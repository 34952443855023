// productStore.ts
import {defineStore} from 'pinia';
import {fetchProducts, fetchAllCategories} from '../api/product'; // Import API functions
import {Supplier} from './supplier';
import {User} from './user';
import {Category} from './recommendedProducts';
export interface ProductState {
    products: Product[];
    productSkip: number;
    selectedProduct: Product | null;
    currentFilter: any;

    allCategories: Category[];
}

export interface ProductPrice {
    edges: [{price:number}]
}
export interface Product {
    uid: string;
    Name: string;
    img: string[];
    Availability: boolean;
    Bio: string;
    Category: string;
    GlutenFree: boolean;
    Price: number;
    UnityPrice: string;
    UnityWeight: number|undefined;
    MediumUnityWeight: number|undefined;
    listedAtConnection?: ProductPrice;
    usersFavorite: User[];
    Category_ID: number;
    Subcategory: string;
    Subcategory_ID: number;
    Supplier_UID: string;
    Vegan: boolean;
    Available: boolean;
    Published:boolean;
    producedBy: Supplier
    liked: boolean;
    comments: Comment[];
    likes: User[];
    supplierUsername: string;
    supplierProfileImage: string;
    mostLikedComment: Comment | null;
    newComment: Comment | null;
    showComment: Comment [];
    showComments: boolean;
    description: string;
    reviews: []
    category: Category

}
export interface ProductSort {
  Available?: 'ASC' | 'DESC';
  Published?: 'ASC' | 'DESC';
}

export interface ProductOptions {
  offset?: number;
  limit?: number;
  sort?: ProductSort[];
}
export const useProductStore = defineStore({
    id: 'product',
    state: () => ({
        products: [],
        productSkip: 0,
        selectedProduct: null,
        currentFilter: null,
        allCategories: [],

    } as ProductState),
    actions: {
        async loadProducts(filter?: any, options?: ProductOptions) {
            if (JSON.stringify(filter) !== JSON.stringify(this.currentFilter)) {
                this.products = [];
                this.productSkip = 0;

                this.currentFilter = filter;
            }
            const fetchOptions = {
                offset: options?.offset ?? this.productSkip,
                limit: options?.limit ?? 50,
                sort: options?.sort,
            };
            let newProducts = await fetchProducts(filter, fetchOptions);

            // Transform and update the products state directly
            newProducts = this.transformProductPrices(newProducts);

            this.products = [...this.products, ...newProducts];
            this.productSkip += newProducts.length;
        },
        setSelectedProduct(product: Product) {
            this.selectedProduct = product;
        },
        async loadAllCategories() {
            this.allCategories = await fetchAllCategories();
        },
        async getProductById(productId: string, currentCompanyUid?: string): Promise<Product | null> {
            const filter = {uid: productId};

            // Fetch products with the currentCompanyUid parameter
            let products = await fetchProducts(filter, {limit: 1}, currentCompanyUid);

            // Transform the products
            //
            products = this.transformProductPrices(products);
            if (products.length > 0) {
                const product = products[0];
                product.liked = product?.usersFavorite?.length > 0;
                return products[0];
            }
            return null;
        },
        transformProductPrices(products: Product[]): Product[] {
            return products.map((product) => {
                const listedPrice = product.listedAtConnection?.edges[0]?.price;
                return {
                    ...product,
                    // Use the listed price if available; otherwise, fall back to the original Price
                    Price: listedPrice !== undefined ? listedPrice : product.Price,
                    listedAtConnection: undefined,
                };
            });
        },
    },
});
