// apiCommon.ts
import F2Api, {LoginInfo} from '../framework2-sdk/f2Api';
import config from '../config';
import {useUserStore} from '../stores/user';
import {set} from '../framework2-sdk/f2ApiSingleton';
import router from '../router';
import {pushNotificationsUserSetup, deletePushNotificationTokenForCurrentUser} from '../notifications';

let api: Api | null = null;

export type Api = F2Api

export interface Credentials {
  username: string;
  password: string;
  applicationKey?: string;
}

export async function initApi(credentials: Credentials) {
    try {
        api = new F2Api({
            onTokenExpired: async function() {
                if (!api) {
                    console.error(new Error('api not set - get'), 'caught');
                    logout();
                    return;
                }
                await api.login(
                    credentials.username,
                    credentials.password,
                    config.applicationKey,
                    false,
                );
            },
            onTokenExpiredFails: async function(e: Error) {
                console.error(e, 'caught', 'api - onTokenExpiredFails');
                logout();
            },
        }) as Api;
        api.setLanguage('it-it');
        set(api);
        api.setBaseUrl(config.f2BaseURL);

        const loginInfo: LoginInfo = await api.login(
            credentials.username,
            credentials.password,
            config.applicationKey,
            false,
        );

        const userInfo = await api.userInfo();
        const userStore = useUserStore();
        userStore.initUser(userInfo);
        pushNotificationsUserSetup();
        userStore.retreiveUserData();
        return loginInfo;
    } catch (e) {
        api = null;
        throw e;
    }
}

export function logout() {
    api = null;
    deletePushNotificationTokenForCurrentUser();
    set(api);
    router.replace('/login');
}

export function getApi() {
    if (api) {
        return api;
    } else {
        console.error(new Error('api not set - get'), 'caught');
        logout();
    }
}

export async function updateUser(userData: any) {
    const api = getApi();
    if (!api) {
        throw new Error('API not initialized');
    }
    return await api.saveUserInfo(userData);
}


export async function recoverPassword(email: string) {
    const noSessionApi = new F2Api({
        baseUrl: config.f2BaseURL,
    });
    noSessionApi.setLanguage('it-it');

    return await noSessionApi.forgotPassword(email);
}


export async function resetPasswordAPI(
    newPassword: string,
    userId: string,
    token: string,
) {
    const noSessionApi = new F2Api({
        baseUrl: config.f2BaseURL,
    });
    noSessionApi.setLanguage('it-it');

    return await noSessionApi.resetPassword(newPassword, userId, token);
}
