import {createRouter, createWebHistory} from '@ionic/vue-router';
import {
    RouteLocationNormalized,
    NavigationGuardNext,
    RouteRecordRaw,
} from 'vue-router';
import TabsPage from '@/views/TabsPage.vue';
import {useUserStore} from '@/stores/user';
import {SecureStoragePlugin} from 'capacitor-secure-storage-plugin';
import * as api from '@/api/apiCommon';
import {Capacitor} from '@capacitor/core';
import config from '../config';

// import {defineComponent} from 'vue';

// const DummyComponent = defineComponent({});

const routes: Array<RouteRecordRaw> = [
    {
        path: '/app-intro',
        component: () => import('@/shared/components/AppIntro.vue'),
        name: 'intro',
    },
    {
        path: '/login',
        component: () => import('@/shared/views/LoginPage.vue'),
        name: 'login',
    },
    {
        path: '/register',
        component: () => import('@/views/RegisterPage.vue'),
        name: 'register',
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/shared/views/ForgotPasswordPage.vue'),
    },
    {
        path: '/:language/reset-password/:userId/:token',
        name: 'ResetPassword',
        component: () => import('@/shared/views/ResetPasswordPage.vue'),
    },
    {
        path: '/:language/confirm-email/:token',
        component: () => import('@/views/ConfirmEmailPage.vue'),
        props: true,
        name: 'confirmEmail',
    },
    {
        path: '/tabs/',
        component: TabsPage,
        name: 'tabs',
        children: [
            {
                path: '',
                redirect: '/tabs/content-list',
                name: 'defaultTab',
            },
            {
                path: 'product-view',
                component: () => import('@/views/ProductView.vue'),
            },
            {
                path: 'notification-view',
                component: () => import('@/views/NotificationView.vue'),
            },
            {
                path: 'post-view',
                component: () => import('@/views/NewPostView.vue'),
                meta: {roles: ['fornitore']},
            },
            {
                path: 'content-list',
                component: () => import('@/views/ContentList.vue'),
            },
            {
                path: '/profile-view/:companyUID?',
                component: () => import('@/views/ProfileView.vue'),
                props: true,
            },
            {
                path: '/profile-view/single-post/:postId',
                component: () => import('@/views/SinglePost.vue'),
                name: 'singlePost',
            },
            {
                path: 'personal-profile',
                component: () => import('@/views/PersonalProfile.vue'),
            },
            {
                path: 'cart-view',
                component: () => import('@/views/CartView.vue'),
            },
            {
                path: '/single-product/:uid',
                component: () => import('@/shared/views/SingleProductView.vue'),
            },
            {
                path: 'personal-profile-edit',
                component: () => import('@/views/PersonalProfileEdit.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

async function handleMobileAppOrDevMode(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
) {
    const store = useUserStore();
    if (!store.user) {
        try {
            const {value} = await SecureStoragePlugin.get({
                key: 'user_credentials',
            });
            const storedCredentials = JSON.parse(value);
            await api.initApi(storedCredentials);
        } catch (error) {
            console.log('No stored credentials found:', error);
        }
    }
    if (store.user && (to.path === '/' || to.path === '')) {
        next('/tabs/');
    } else if (!store.user && (to.path === '/' || to.path === '')) {
        next('/app-intro');
    } else if (
        !store.user &&
    (to.name === 'tabs' ||
      to.matched.some((record: RouteRecordRaw) => record.name === 'tabs'))
    ) {
        next({path: '/'});
    } else {
        next();
    }
}

function handleWebContext(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
) {
    const singlePostPageName = 'singlePost';
    const registerPageName = 'register';
    const confirmEmailPageName = 'confirmEmail';
    const resetPasswordPageName = 'ResetPassword';

    // Check if the navigation is to the singlePost page or the register page
    if (
        to.name === singlePostPageName ||
    to.name === registerPageName ||
    to.name === confirmEmailPageName ||
    to.name === resetPasswordPageName
    ) {
    // If yes, proceed with the navigation
        next();
    } else {
    // If not, redirect to the register page
        next({name: registerPageName});
    }
}

router.beforeEach(
    async (
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
        next: NavigationGuardNext,
    ) => {
        const isWeb = Capacitor.getPlatform() === 'web';
        if (config.development || !isWeb) {
            await handleMobileAppOrDevMode(to, from, next);
        } else if (isWeb) {
            handleWebContext(to, from, next);
        }
    },
);

export default router;
